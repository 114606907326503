import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import toast from "react-hot-toast"
import Schema from "../components/addon/schema"
import useScript from "../service/useScript"
import LoadMoreButton from "../components/addon/loadMoreButton"
import SimpleReactValidator from "simple-react-validator"
import { acceptOnlyNumbers } from "../service/helper"
import ParagraphTabsTextOnly from "../components/pageSections/paragraphTabsTextOnly"
import PhoneInput from 'react-phone-input-2'
import Select from 'react-select'
import USER_API from "../api/user"
import UseCustomValidator from "../service/simpleValidator"
import LinkButton from "../components/addon/button/linkButton"
import APIButton from "../components/addon/button/apiButton"
import UPLOAD_API from "../api/postings"
import Thankyou from '../components/addon/thankYou'

import 'react-phone-input-2/lib/style.css'

const InternationaPatient = (props) => {
    const [selectedVideo, setSelectedVideo] = useState(null)
    let [videoData, setVideoData] = useState([])
    let [defaultLimit] = useState(3)
    const [loading, setLoading] = useState(false)
    const [, forceUpdateForRegister] = useState();
    const [contactDetailsValidated, setContactDetailsValidated] = useState(false)
    let [commentData, setCommentData] = useState([])
    const [countryDataSearch, setCountryDataSearch] = useState({})
    const [dailCode, setDailCode] = useState("in")
    const [countryDialCodes, setCountryDialCodes] = useState([])
    const [aadharImg, setAadharImg] = useState(null);
    const [fileError, setFileError] = useState('')
    const [thankYou, setThankYou] = useState(false)

    const { customMobileValidator } = UseCustomValidator()

    const metaTags = {}
    const schema = ''
    const data = props?.data?.nodeTemplatePages;
    const pageComponents = data?.relationships?.field_component_type

    let components = data?.relationships?.components ? data.relationships.components : []
    const blogCategory = props?.data?.allTaxonomyTermBlogCategory?.edges
    console.log('sdsdsdsd',blogCategory)
    let tabComponents = components.filter(e => e?.__typename === "paragraph__tab_components")
    const videoSectionVideos = pageComponents[9]?.relationships?.field_components[0]?.relationships?.field_components

    let commentSection = pageComponents[10]
    const commentSectionComments = commentSection?.relationships?.field_components
    console.log('pageComponents===',pageComponents)
    const setVideo = (videoUrl) => {
        let data_src = videoUrl.split('=')
        if(data_src[1]) {
            setSelectedVideo(`https://www.youtube.com/embed/${data_src[1]}`)
        }
    }

    let panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    let aadharRegex =/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;

    const mobileValidator = customMobileValidator

    const [personalDetailValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => <p className="field_error" > {message} </p>,
        validators: {
            validateAadhar: {
                message: 'The :attribute should be a valid aadhar no.',
                rule: (val, params, validator) => {
                    if(aadharRegex.test(val)) {
                        return val
                    } else {
                        return false
                    }
                },
                messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                required: false  // optional
            },
        }
    })))

    const [contactDetailValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" > {message} </p>}
    )))

    const thankyouScroll = useRef()

    const [registerForm, setRegisterForm] = useState({
        webform_id: "international_form",
        title: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        age: "",
        gender: "",
        country_code: "",
        country_name: "",
        mobile_no:"",
        email_address: "",
        speciality: "",
        comments: "",
        location: '',
        MobileExt: '',
        upload_report: ''
    });

    const loadMoreVideos = () => {
        const counter = videoData.length + defaultLimit
        const arr = ''//[...videoSectionVideos?.slice(videoData.length, counter)]
        setVideoData([...videoData, ...arr])
    }

    const loadMoreComments = () => {
        const counter = commentData.length + defaultLimit
        const arr = ''//[...commentSectionComments?.slice(commentData.length, counter)]
        setCommentData([...commentData, ...arr])
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRegisterForm({
            ...registerForm,
            [name]: value,
        });
    };

    
    const handleSelectChange = (e) => {
      const val = e?.label
      const contryPrefix = e?.country_prefix?.toLowerCase()
      setRegisterForm({
          ...registerForm,
          'country_name': val,
          'country_code': e?.value
      });
      setDailCode(contryPrefix)
    };

const removeImg = () => {
  setAadharImg(null)
  const el = document.getElementById('reg_img_upload')
  if(el?.value) el.value = ''
}

const handleImgUpload = async (e) => {
  let fileName =  e.target.value.toLowerCase()
  let file = e.target.files[0]
  if (file && fileName.includes('.jpg') 
      || file && fileName.includes('.png') 
      || file && fileName.includes('.pdf')
      || file && fileName.includes('.jpeg')) {
      setFileError("");
      setAadharImg(file)
      //uploadImage()
      if(file){
        uploadImage(file)
      }
  } else {
      setFileError("Please upload your Img in .jpg or .png or pdf format")
  }
}

  const getCountryData = () => {
    USER_API.getAllCountry()
        .then(res => {
          if (res?.Data) {
              let allCountryCodes = []
              res?.Data.map(item => {
                  allCountryCodes.push(item?.country_prefix)
                  // Assign new key
                  item['label'] = item['country_name']; 
                  item['value'] = item['country_code']; 
          
                  // Delete old key
                  delete item['country_name']; 
                  delete item['country_code']; 
          
                  return item;
              })
              setCountryDataSearch(res?.Data)
              setCountryDialCodes(allCountryCodes)
          }
        })
        .catch(err => {
            console.log(err)
        })
}

const uploadImage = async(file) => {
  setLoading(true)
  const res = await UPLOAD_API.submitImage(file)
    .catch(err => {
        console.log(err)
        toast.error("Image Upload Failed.")
        setLoading(false)
        return
    })
    let imgData = {}
    if(res?.fid && res?.fid[0]?.value) {
        imgData = {
            fid: res?.fid[0]?.value
        }
        setRegisterForm({
          ...registerForm,
          upload_report: imgData?.fid
        })
        setLoading(false)
    } else {
        setLoading(false)
        return
    }
}

    const submitRegistrationForm = async () => {
        console.log('registerFormregisterForm',registerForm)
        setLoading(true)
        const formResult = personalDetailValidator.current.allValid()
        const mobileValidatorResult = mobileValidator.current.allValid()
        if (formResult === false) {
            personalDetailValidator.current.showMessages()
            forceUpdateForRegister(1)
        }
        
        if (mobileValidatorResult === false) {
            mobileValidator.current.showMessages()
            forceUpdateForRegister(1)
        }
        
        if (formResult && !registerForm?.country_name) {
            toast.error("Please select country before proceeding")
            return
        }
        
        if (formResult && mobileValidatorResult) {
            const response = await UPLOAD_API.submitFormData(registerForm)
            .catch(err => {
                console.log(err)
                toast.error("Looks like something went wrong. Please try again later.")
            })
            if (response) {
                setThankYou(true);
                thankyouScroll.current.scrollIntoView({ behavior: 'smooth' })
                // resetForm();
                //window.scroll({ top: 0, behavior: "smooth" })
            }else{
                toast.error("Something went wrong.")
                setLoading(false)
            }
        }else{
          setLoading(false)
        }
    }

    useEffect(() => {
        loadMoreVideos()
    },[videoSectionVideos])

    useEffect(() => {
        loadMoreComments()
        // setTimeout(() => {
        //     window.readMoreLessFunction();
        // }, 500)
    },[commentSectionComments])

    useEffect(() => {
      getCountryData()
  },[])

  const resetThankyou = (value) => {
    if(window) window.location.reload();
  }

    useScript("/assets/js/latest-technology-slider.js")
    useScript("/assets/js/custom_select.js");
    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/room-type.css", "/assets/css/testimonial.css", "/assets/css/about-through-the-ages.css", "/assets/css/latest-technology-tab.css"],
                    }}
                    tags={metaTags}
                />
                <Schema schema={schema} />
                <main className="innerpage">
                    {
                        pageComponents?.map( item => {
                            if(item?.paragraph_type?.drupal_internal__target_id === "banner"){
                                return(
                                    <section className="inner_common_banner">
                                        <div className="banner_img">
                                            {/* <source media="(min-width:1024px)"
                                                srcset={item?.relationships?.field_banner_image?.uri?.url || ''} />
                                            <source media="(min-width:300px)"
                                                srcset={item?.relationships?.field_mobile_banner?.uri?.url || ''} /> */}
                                            {
                                              item?.relationships?.field_mobile_banner && item?.relationships?.field_mobile_banner?.uri?.url !== '' && <img className="d-md-none" src={item?.relationships?.field_mobile_banner?.uri?.url || ''} alt={item?.field_title || ''} title={item?.field_title || ''} />
                                            }
                                            <img className="d-md-block d-none" src={item?.relationships?.field_banner_image?.uri?.url || ''} alt={item?.field_title || ''} title={item?.field_title || ''} />
                                        </div>
                                        <div className="banner_content text_left text_dark">
                                            <div className="container">
                                                <h1 dangerouslySetInnerHTML={{__html: item?.field_title || ''}}></h1>
                                                <p dangerouslySetInnerHTML={{ __html: item?.field_subtitle }}></p>
                                            </div>
                                        </div>
                                    </section>
                                )
                            }
                        })
                    }

                    <section className="section_bg pt-first-section pb-section about-ages latestTechnologyWrap">
                        <div className="container">
                            <h3 className="pb-3">Why P. D. Hinduja Hospital?</h3>          
                        </div>
                        <ParagraphTabsTextOnly
                            content={tabComponents}
                            />
                    </section>
                    {
                      thankYou ? <Thankyou ref={thankyouScroll} setThankYou={resetThankyou} /> : (
                        <>
                          <section className="section-bg section-py">
                            {   pageComponents.length > 4 && pageComponents[5] &&
                                <div className="container">
                                    <div className="pb-3">
                                        { console.log('pageComponents[5]===',pageComponents[5])}
                                        <h3 className="pb-3">{pageComponents[5]?.field_title}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: pageComponents[5]?.field_description?.processed}}></div>
                                    </div>
                                    <div className="white_rounded_box">
                                        <div className="report_upload_section">
                                            <div className="form_wrap international_form_wrap" id="report_upload_wrap">
                                                <div className="form_block pl-0">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-2 col-3">
                                                                    <div className="form-group select-dd">
                                                                        <label>Title</label>
                                                                        <select onChange={handleInputChange} value={registerForm.title} name="title" id="title" className="form-control custom-select" placeholder="Title">
                                                                            <option hidden>Choose Title</option>
                                                                            <option value="Mr">Mr.</option>
                                                                            <option value="Miss">Ms.</option>
                                                                            <option value="Miss">Miss.</option>
                                                                        </select>
                                                                        {personalDetailValidator.current.message('Title', registerForm.title, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-9">
                                                                    <div className="form-group -animated">
                                                                    <label>First Name <span className="mandatory">*</span></label>
                                                                        <input
                                                                            onChange={handleInputChange}
                                                                            id="first_name"
                                                                            name="first_name"
                                                                            disabled={false}
                                                                            value={registerForm.first_name}
                                                                            className="form-control"
                                                                            type="text"
                                                                            autoComplete="nope"
                                                                            />
                                                                        {personalDetailValidator.current.message('FirstName', registerForm.first_name, 'required|alpha_space')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-6">
                                                                    <div className="form-group -animated">
                                                                        <label>Middle Name</label>
                                                                        <input onChange={handleInputChange} id="middle_name" name="middle_name" value={registerForm.middle_name} className="form-control" type="text" autoComplete="nope" />
                                                                        {personalDetailValidator.current.message('MiddleName', registerForm.middle_name, 'alpha')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-6">
                                                                    <div className="form-group -animated">
                                                                        <label>Last Name <span className="mandatory">*</span></label>
                                                                        <input onChange={handleInputChange} id="last_name" disabled={false} name="last_name" value={registerForm.last_name} className="form-control" type="text" autoComplete="nope" />
                                                                        {personalDetailValidator.current.message('LastName', registerForm.last_name, 'required|alpha')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-12 col-md-4 col-lg-3">
                                                                    <div className={`form-group -animated -active}`}>
                                                                        <label>Age (Years)</label>
                                                                        <input onChange={handleInputChange} id="age" name="age" value={registerForm.age} className="form-control" type="text" autoComplete="nope" maxLength={2} />
                                                                        {personalDetailValidator.current.message('Age', registerForm.age, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 col-lg-9">
                                                                    <div className="form-group radio-group">
                                                                        <label className="radio-main-label">Gender <span className="mandatory">*</span></label>
                                                                        <div className="row">
                                                                            <div className="col-md-3 col-6">
                                                                                <div className="custom-control custom-radio">
                                                                                    <input onChange={handleInputChange} value="M" type="radio" name="gender" id="M1"/>
                                                                                    <label htmlFor="M1">Male</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 col-6">
                                                                                <div className="custom-control custom-radio">
                                                                                    <input onChange={handleInputChange} value="F" type="radio" name="gender" id="F"/>
                                                                                    <label htmlFor="F">Female</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 col-6">
                                                                                <div className="custom-control custom-radio">
                                                                                    <input onChange={handleInputChange} value="O" type="radio" name="gender" id="O"/>
                                                                                    <label htmlFor="O">Other</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {personalDetailValidator.current.message('Gender', registerForm.gender, 'required')}
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-12 col-md-12 col-lg-3">
                                                                    <div className="form-group -animated">
                                                                      <label>Email Address <span className="mandatory">*</span></label>
                                                                      <input onChange={handleInputChange} autoComplete={'off'} name="email_address" value={registerForm.email_address} className="form-control" type="text" />
                                                                      {personalDetailValidator.current.message('Email', registerForm.email_address, 'required|email')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 col-lg-3">
                                                                    <div className="form-group select-dd">
                                                                      <label>Country <span className="mandatory">*</span></label>
                                                                      <Select name="country_name" options={countryDataSearch} onChange={handleSelectChange} className="selectWithSearch" placeholder="Select Country" />
                                                                      {personalDetailValidator.current.message('Country', registerForm.country_name, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 col-md-9 col-lg-3">
                                                                    {/* <div className="form-group -animated">
                                                                      <label>Mobile No. <span className="mandatory">*</span></label>
                                                                      <input onChange={handleInputChange} value={registerForm.mobile_no} name="mobile_no" className="form-control" type="text" maxLength={10} autoComplete="nope" />
                                                                      {mobileValidator.current.message('mobile_no', registerForm.mobile_no, 'required|min:10|max:10|validateMobile')}
                                                                    </div> */}
                                                                    <div className={`form-group -animated -active`}>
                                                                      <label>Mobile <span className="mandatory">*</span></label>
                                                                      <PhoneInput
                                                                          className="mt-2"
                                                                          autoComplete={'off'}
                                                                          preferredCountries={countryDialCodes}
                                                                          country={dailCode}
                                                                          value={registerForm.MobileExt}
                                                                          countryCodeEditable={false}
                                                                          //disabled={verifyOpen}
                                                                          placeholder={''}
                                                                          onChange={(value, country, e, formattedValue) => {
                                                                            setRegisterForm({
                                                                                  ...registerForm,
                                                                                  ['MobileExt'] : country?.dialCode,
                                                                                  ['mobile_no'] : value.substring(country?.dialCode?.length)
                                                                              })
                                                                          }}
                                                                          inputProps={{
                                                                              name: 'mobile_no',
                                                                              required: true,
                                                                          }}
                                                                      />
                                                                      {mobileValidator.current.message('Mobile ', registerForm.mobile_no , 'required|validateMobile')}
                                                                      {/*!verifyOpen && <LinkButton title={`Verify with OTP`} onBtnClick={onVerify} loading={otpLoading} />*/}
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-12 col-md-12 col-lg-4">
                                                                    <div className="form-group select-dd">
                                                                        <label>Speciality <span className="mandatory">*</span></label>
                                                                        <select name="speciality" onChange={handleInputChange} value={registerForm.speciality} className="form-control custom-select" placeholder="Select Speciality">
                                                                          <option value="">Select Speciality</option>
                                                                          {
                                                                            blogCategory.map( item => {
                                                                              return(
                                                                                <option value={item?.node?.name}>{item?.node?.name}</option>
                                                                              )
                                                                            })
                                                                          }
                                                                        </select>
                                                                        {personalDetailValidator.current.message('Speciality', registerForm.speciality, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 col-lg-4">
                                                                    <div className="form-group select-dd">
                                                                        <label>Location <span className="mandatory">*</span></label>
                                                                        <select name="location" onChange={handleInputChange} value={registerForm.location} className="form-control custom-select" placeholder="Select Location">
                                                                          <option value="">Select Location</option>
                                                                          <option value="Mahim">Mahim</option>
                                                                          <option value="Khar">Khar</option>
                                                                          <option value="Any">Any</option>
                                                                        </select>
                                                                        {personalDetailValidator.current.message('Location', registerForm.location, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 col-lg-8 uploadMainSecWrap">
                                                                        <div className="form-group upload-file -animated">
                                                                            <label>Upload Report <span className="mandatory">*</span></label>
                                                                            <div className="input-group">
                                                                              <input type="text" className="form-control" readonly />
                                                                              <div className="input-group-btn">
                                                                                  <span className="fileUpload">
                                                                                      <span className="upl upload" id="upload">{aadharImg ? 'Uploaded' : 'Upload'} </span>
                                                                                      {aadharImg ? <a className="remove otp_color" id="upload" onClick={() => removeImg()}>Remove</a> : null}
                                                                                      <input id='reg_img_upload' name="resume" className="form-control" type="file" onChange={handleImgUpload} />
                                                                                  </span>
                                                                              </div>
                                                                            </div>
                                                                        </div>
                                                                        {personalDetailValidator.current.message('IdentificationProofImage', aadharImg, 'required')}
                                                                        {fileError.length > 0 ? <p className="field_error">{fileError}</p> : ""}
                                                                    {aadharImg?.name 
                                                                      ? <div className="notes border-0 mb-2">
                                                                          <ul>
                                                                              <li>{aadharImg?.name}</li>
                                                                          </ul>
                                                                      </div>
                                                                      : null}
                                                                    <div className="mb-2">
                                                                        <div className="notes">
                                                                            <ul>
                                                                                <li>Make sure that:</li>
                                                                                <li>1. The image is not blurry.</li>
                                                                                <li>2. All the details are clearly visible.</li>
                                                                                <li>3. Image should not exceed 2 MB.</li>
                                                                                <li>4. Only .jpg .png format permitted.</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group textarea">
                                                                <label>Comments </label>
                                                                <textarea value={registerForm.comments} onChange={handleInputChange} name="comments" className="form-control"></textarea>
                                                                {personalDetailValidator.current.message('Comments', registerForm.comments, 'required')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="row mt-4">
                                                        <div className="col-12">
                                                          <APIButton 
                                                              title={`Continue`} 
                                                              loading={loading}  
                                                              onBtnClick={(e) => !loading ? submitRegistrationForm(e) : {}}  
                                                              className={`btn btn-primary`} 
                                                          />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </section>
                        </>
                      )
                    }

                    <section className="specialityBoxWrap">
                        {   pageComponents.length > 5 && pageComponents[6] &&
                            <div className="container">
                                <h3 className="pb-3">{pageComponents[6]?.field_title}</h3>
                                <div className="specialityBoxSlider">
                                    {
                                        pageComponents[6]?.relationships?.field_component_items?.map( item =>{
                                            return(
                                                <a href={(item?.field_link?.uri).replace("internal:", "")} className="box_sec" target="_blank">
                                                    <span className="icon_image"><img src={item?.relationships?.field_icon?.uri?.url} alt={item?.field_link?.title} title={item?.field_link?.title} /></span>
                                                    <p>{item?.field_link?.title}</p>
                                                </a>
                                            )
                                        })
                                    }
                                </div>   
                            </div>
                        }   
                    </section>  

                    <section className="room_cards_section">
                        {   pageComponents.length > 6 && pageComponents[7] &&
                            <div className="container">
                                <h3 className="pb-3">{pageComponents[7]?.field_title || ''}</h3>
                                <div className="row">
                                {
                                    pageComponents[7]?.relationships?.field_component_items?.map( item => {
                                        return(
                                            <div className="col-lg-4 col-md-6 mb-4">
                                                <div className="card">
                                                    <img className="lazyload" src={item?.relationships?.field_location_images[0]?.uri?.url} alt="Thumbnail img" />
                                                    <div className="card-body" dangerouslySetInnerHTML={{__html: item?.field_text?.processed}}></div>
                                                    <div className="card-link text-center">
                                                        <a href={(item?.field_link?.uri).replace("internal:", "")} target="_blank" className="btn
                                                        btn-primary">{item?.field_link?.title}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        }
                    </section>
                    
                    <section className="section-py">
                        {   pageComponents.length > 7 && pageComponents[8] &&
                            <div className="container">
                                <h3 className="pb-3">{pageComponents[8]?.field_title || ''}</h3>
                                <div className="plan-trip-view">
                                    <ul className="plan-trip-list">
                                        {
                                            pageComponents[8]?.relationships?.field_component_items?.map( item => {
                                                return(
                                                    <li>
                                                        <div className="icon">  
                                                            <img src={item?.relationships?.field_icon?.uri?.url} alt="img" loading="lazy" />
                                                        </div>
                                                        <div className="title">
                                                            <p className="mb-1"><strong>{item?.field_title}</strong></p>
                                                            <div dangerouslySetInnerHTML={{__html : item?.field_description?.processed}}></div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>   
                            </div>
                        }
                    </section>
                    
                    
                        {   pageComponents.length > 8 && pageComponents[9] &&
                            <section className="section_bg pt-first-section pb-section testimonialWrap">
                            <div className="container">
                                <div className="py-2">
                                    <h3 className="pb-3">{pageComponents[9]?.relationships?.field_components[0]?.field_name}</h3>
                                    <p>{pageComponents[9]?.relationships?.field_components[0]?.field_subtitle}</p>
                                </div>
                                <div className="videoWrap">
                                    <div className="row">
                                        {videoData?.length 
                                        ? videoData.map((video, index) => {
                                            let imageUrl = video?.relationships?.field_image?.uri?.url
                                            let videoUrl = video?.field_link?.uri
                                            return (
                                                <div className="col-md-4" key={index}>
                                                    <div className="videoBox">
                                                        <a href="javascript:;" onClick={() => setVideo(videoUrl)} className="video-popup" data-toggle="modal" data-target="#modal_video" data-src="q_mU0XUmlfY">
                                                            <img src={imageUrl} alt={video?.field_title} />
                                                            <span className="playIcon"><img src="/assets/images/testimonial/video-play-icon.png" alt="Video Play Icon" /></span>
                                                        </a>
                                                        <h4>{video?.title}</h4>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : null}
                                        {videoData.length != videoSectionVideos.length 
                                        ? <div className="col-md-12">
                                            <div className="btnWrap text-center">
                                                <LoadMoreButton
                                                    label="Load More"
                                                    callbackFunction={loadMoreVideos}
                                                />
                                            </div>  
                                        </div>
                                        : null}
                                    </div>    
                                </div>
                            </div>
                            </section>
                        }
                    

                    
                        {   pageComponents.length > 9 && pageComponents[10] &&
                            <section className="section_bg pt-first-section pb-section testimonialWrap">
                            <div className="container expereanceWrap">
                                <h2>{commentSection?.field_name}</h2>
                                <div className="expereanceContent">
                                    {commentData?.length 
                                    ?  commentData.map((comment, index) => {
                                        return (
                                            <div className="expereanceBox" key={index}>
                                                <span className="quotes openQuotes">
                                                    <img src="/assets/images/testimonial/open-quotes.svg" alt="Open Quotes Icon" />
                                                </span>  
                                                <div className="textContent">
                                                    <div className="more" dangerouslySetInnerHTML={{__html: comment?.field_description?.processed }}></div>  
                                                    <h4>- {comment?.field_title}</h4>
                                                </div>
                                                <span className="quotes closeQuotes">
                                                    <img src="/assets/images/testimonial/close-quotes.svg" alt="Close Quotes Icon" />
                                                </span>  
                                            </div>  
                                        )
                                    })
                                    : null}
                                    {commentData.length != commentSectionComments.length 
                                    ? <div className="col-md-12">
                                        <div className="btnWrap text-center">
                                            <LoadMoreButton
                                                label="Load More"
                                                callbackFunction={loadMoreComments}
                                            />
                                        </div>  
                                    </div>
                                    : null}
                                </div>
                            </div>
                            </section>
                        }
                    
                </main>
                <div className="modal fade modal_video modal-center" id="modal_video" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                        
                            <div className="modal-body">
                                <button onClick={() => setSelectedVideo(null)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">X</span>
                                </button>
                                <iframe width="100%" height="315" src={selectedVideo ? selectedVideo : ''} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export const query = graphql`
query InternationalQuery {
    nodeTemplatePages(path: {alias: {eq: "/international-patient"}}) {
      id
      title
      relationships {
        components: field_component_type {
          __typename
          ...ParagraphTabComponentWhatsNew
        }
      }
      relationships {
        field_breadcrumb {
          field_title
          field_breadcrumb_link {
            title
            uri
          }
        }
        field_component_type {
          ... on paragraph__banner {
            id
            paragraph_type {
              drupal_internal__target_id
            }
            field_title
            field_subtitle
            field_banner_link {
              title
              uri
            }
            relationships {
              field_banner_image {
                publicUrl
                url
                uri {
                  url
                  value
                }
              }
              field_mobile_banner {
                publicUrl
                url
                uri {
                  url
                  value
                }
              }
            }
          }
          ... on paragraph__html_text {
            id
            paragraph_type {
              drupal_internal__target_id
            }
            field_text {
              processed
            }
          }
          ... on paragraph__tab_components {
            id
            paragraph_type {
              drupal_internal__target_id
            }
            field_title
            relationships {
              field_components {
                ... on paragraph__html_text {
                  id
                  field_text {
                    processed
                  }
                }
                ... on paragraph__image_text {
                  id
                  paragraph_type {
                    drupal_internal__target_id
                  }
                  field_text_demo {
                    processed
                  }
                  relationships {
                    field_image {
                      publicUrl
                      uri {
                        url
                        value
                      }
                      url
                    }
                    field_image_demo {
                      publicUrl
                      uri {
                        url
                        value
                      }
                      url
                    }
                  }
                }
              }
            }
          }
          ... on paragraph__title_and_description {
            id
            paragraph_type {
              drupal_internal__target_id
            }
            field_title
            field_text {
              processed
            }
            field_description {
              processed
            }
            field_cta_link {
              title
              uri
            }
          }
          ... on paragraph__our_specialities_component {
            id
            paragraph_type {
              drupal_internal__target_id
            }
            field_title
            relationships {
              field_component_items {
                field_link {
                  uri
                  title
                }
                field_title
                field_description {
                  processed
                }
                relationships {
                  field_icon {
                    publicUrl
                    uri {
                      url
                      value
                    }
                    url
                  }
                }
              }
            }
          }
          ... on paragraph__book_now_components {
            id
            field_title
            relationships {
              field_component_items {
                field_text {
                  processed
                }
                field_link {
                  title
                  uri
                }
                field_feature
                field_description {
                  processed
                }
                field_cta_link {
                  title
                  uri
                }
                id
                paragraph_type {
                  drupal_internal__target_id
                }
                relationships {
                  field_location_images {
                    publicUrl
                    uri {
                      url
                      value
                    }
                    url
                  }
                }
              }
            }
          }
          ... on paragraph__image_text {
            id
            paragraph_type {
              drupal_internal__target_id
            }
            field_text_demo {
              processed
            }
            relationships {
              field_image {
                publicUrl
                uri {
                  url
                  value
                }
                url
              }
              field_image_demo {
                publicUrl
                uri {
                  url
                  value
                }
                url
              }
            }
          }
          ... on paragraph__section {
            paragraph_type {
              drupal_internal__target_id
            }
            field_name
            field_section_type
            relationships {
              field_components {
                ... on paragraph__title_and_description_components {
                  id
                  field_title
                  field_subtitle
                  field_name
                  field_link {
                    title
                    uri
                  }
                  relationships {
                    field_components {
                      ... on paragraph__image_title_desc_link {
                        id
                        field_title_1
                        field_title
                        field_link {
                          title
                          uri
                        }
                        field_description {
                          processed
                        }
                        relationships {
                          field_image {
                            publicUrl
                            uri {
                              url
                              value
                            }
                            url
                          }
                          field_mobile_image {
                            publicUrl
                            uri {
                              url
                              value
                            }
                            url
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__title_and_description {
                  id
                  field_title
                  field_text {
                    processed
                  }
                  field_description {
                    processed
                  }
                  field_cta_link {
                    title
                    uri
                  }
                  paragraph_type {
                    drupal_internal__target_id
                  }
                }
              }
            }
          }
          ... on paragraph__image_text {
            id
          }
        }
      }
    }
    allTaxonomyTermBlogCategory {
      edges {
        node {
          id
          name
        }
      }
    }
  }

  fragment ParagraphTabComponentWhatsNew on paragraph__tab_components {
    id
    title: field_title
    relationships {
      components: field_components {
        __typename
        ...ParagraphImageComponent
        ...ParagraphHTMLText
        ...ParagraphImageTextWhatsnew
      }
    }
  }
  
fragment ParagraphImageTextWhatsnew on paragraph__image_text {
    id
    text: field_text_demo {
      processed
    }
    image: field_image_demo {
      alt
    }
    relationships {
      image: field_image_demo {
        id
        uri {
          value
          url
        }
      }
      mainimage: field_image {
        id
        uri {
          value
          url
        }
      }
    }
  }  
`
export default InternationaPatient